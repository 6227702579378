<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">
            Save posts

            <v-chip
               color="#3AC2D2"
               class="white--text"
               x-small
            >
              Beta
            </v-chip>
          </div>
          <span class="subtitle-1 light-grey">
            Save new posts from influencers you're collaborating with.

            <a href="https://intercom.help/Make-influence-com/en/articles/6248346-how-does-the-content-feature-work" target="_blank">
              Read more
            </a>
          </span>
        </div>
        <v-spacer></v-spacer>

        <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" icon @click="showMentionsDialog = true;">
              <v-icon>fal fa-at</v-icon>
            </v-btn>
          </template>

          <span>@Mentions</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-container class="p-0" fluid>
          <v-row wrap>
            <v-col md="4" sm="6" xs="12">
              <campaign-autocomplete
                 v-model="campaignId"
                 :return-object="false"
                 :display-draft="false"
              ></campaign-autocomplete>
            </v-col>
            <v-col md="4" sm="6" xs="12">
              <influencer-autocomplete
                 v-model="influencerId"
                 ref="influencerAutocomplete"
                 :return-object="false"
                 :disabled="campaignId === null"
              ></influencer-autocomplete>
            </v-col>
            <v-col md="4" sm="6" xs="12">
              <v-btn
                 color="primary"
                 type="submit"
                 class="mt-4"
                 :disabled="campaignId === null"
                 :loading="loading"
                 @click="search()"
              >
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-checkbox
           v-model="hasMentions"
           label="Only show @Mentions"
           small
           @change="instagramUsernames.length === 0 && $event ? showMentionsDialog = true : null;"
        ></v-checkbox>

        <br/>

        <div v-if="posts.length === 0">

          <i v-if="loading">
            Searching...
          </i>

          <i v-else-if="total === null">
            Choose a campaign to see posts
          </i>

          <i v-else-if="campaignId && total !== null">
            No posts found
          </i>

          <img
             src="@/assets/no-content-business.svg"
             alt="No posts"
             class="default-img"
          />
        </div>

        <div v-if="total" class="text-right mb-2">
          {{ posts.length }} out of {{ total }}
<!--          <v-btn-toggle small>-->
<!--            <v-btn-->
<!--               small-->
<!--               :color="selectedGridStyle === 'masonry' ? 'primary' : ''"-->
<!--               @click="selectedGridStyle = 'masonry'"-->
<!--            >-->
<!--              <v-icon small :color="selectedGridStyle === 'masonry' ? 'white' : ''">fal fa-stream</v-icon>-->
<!--            </v-btn>-->

<!--            <v-btn-->
<!--               small-->
<!--               :color="selectedGridStyle === 'split' ? 'primary' : ''"-->
<!--               @click="selectedGridStyle = 'split'"-->
<!--            >-->
<!--              <v-icon small :color="selectedGridStyle === 'split' ? 'white' : ''">fal fa-th-large</v-icon>-->
<!--            </v-btn>-->
<!--          </v-btn-toggle>-->
        </div>

        <v-container class="p-0" grid-list-lg fluid>
          <v-row v-masonry>
            <v-col
               v-for="item in posts"
               :key="item.uuid"
               xs="12" sm="6" md="4" lg="4"
            >
              <attachable-media-card
                 :value="item"
                 :selected="selectedUuids.filter(selectedItem => selectedItem === item.uuid).length > 0"
                 :recentlyAttached="recentlyAttachedUuids.filter(selectedItem => selectedItem === item.uuid).length > 0"
                 @change="togglePostSelection(item.uuid)"
                 @influencerClick="selectInfluencer($event)"
              />
            </v-col>
          </v-row>

          <v-pagination
             v-if="posts.length"
             v-model="currentPage"
             class="mt-5"
             :length="lastPage"
             :total-visible="7"
             @input="_getInfluencerChannelPosts"
          ></v-pagination>

          <v-bottom-navigation v-if="selectedUuids.length > 0" :background-color="selectedUuids.length > maxSaveLimit ? 'error' : 'success'" fixed style="z-index: 100;">
            <v-btn block :disable="selectedUuids.length > maxSaveLimit" @click="selectedUuids.length > maxSaveLimit ? null : showAttachDialog = true">
              <span class="white--text">{{ attachButtonText }}</span>
              <v-icon style="color:#FFF !important;">fal fa-heart</v-icon>
            </v-btn>
          </v-bottom-navigation>
          <br/><br/>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showMentionsDialog" max-width="550px">
      <v-card>
        <v-card-title>
          @Mentions
        </v-card-title>
        <v-card-text>
          <p>
            Type in your Instagram username(s) to search post mentions
          </p>

          <v-text-field
             v-for="(username, index) in instagramUsernames"
             :key="index"
             v-model="instagramUsernames[index]"
             label="Instagram"
             prepend-inner-icon="fal fa-at"
          >
            <div slot="append-outer">
              <v-icon small @click="instagramUsernames.splice(index, 1)">fal fa-times</v-icon>
            </div>
          </v-text-field>

          <v-text-field
             v-if="instagramUsernames.length < instagramUsernameCountLimit"
             v-model="newInstagramUsername"
             :placeholder="user.business.display_name"
             prepend-inner-icon="fal fa-at"
          ></v-text-field>
          <div class="text-right">
            <v-btn
               :color="newInstagramUsername.length > 0 ? 'success' : 'primary'"
               :outlined="newInstagramUsername.length === 0"
               :disabled="newInstagramUsername.length === 0 || instagramUsernames.length >= instagramUsernameCountLimit"
               small
               @click="instagramUsernames.push(newInstagramUsername); newInstagramUsername = '';"
            >
              Add
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showMentionsDialog = false;">Close</v-btn>
          <v-btn color="primary" @click="saveInstagramUsernames()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="showInfoDialog" v-model="showInfoDialog" max-width="750px" persistent>
      <v-card>
        <v-card-title>
          You've selected your first post!
        </v-card-title>
        <v-card-text>
          Click the green button at the bottom to save.
          <br/>
          <br/>

          <v-img
             :src="saveContentExplainerImage()"
          ></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showInfoDialog = false;">
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAttachDialog" max-width="350px">
      <v-card>
        <v-card-title>
          <v-icon small class="mr-2">fas fa-heart</v-icon>
          Save selected posts ({{ selectedUuids.length }})
        </v-card-title>
        <v-card-text>
          It's only allowed to save posts promoting your own brand. Influencers are notified of the posts you save.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showAttachDialog = false;">Luk</v-btn>
          <v-btn color="success" @click="saveSelectedPosts()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDetachDialog" max-width="350px">
      <v-card>
        <v-card-title>
          <v-icon small class="mr-2">fas fa-heart-broken</v-icon>
          Remove post
        </v-card-title>
        <v-card-text>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showDetachDialog = false;">Luk</v-btn>
          <v-btn color="error" outlined @click="detachSelectedPost()">
            Remove post
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import AttachableMediaCard from "../../cards/AttachableMediaCard";
import CampaignAutocomplete from "../autocomplete/CampaignAutocomplete";
import InfluencerAutocomplete from "../autocomplete/InfluencerAutocomplete";

export default {
  components: {
    AttachableMediaCard,
    CampaignAutocomplete,
    InfluencerAutocomplete,
  },
  mixins: [search_filters_url],
  data() {
    return {
      posts: [],
      // selectedGridStyle: 'masonry',
      showAttachDialog : false,
      showInfoDialog : false,
      showMentionsDialog : false,
      instagramUsernames: [],
      newInstagramUsername: "",
      instagramUsernameCountLimit: 3,
      maxSaveLimit: 100,
      selectedUuids: [],
      recentlyAttachedUuids: [],
      selectedPostToDetach: null,
      loading: false,
      currentPage: 1,
      lastPage: 1,
      total: null,
      limit: 21,
      hasMore: true,
      campaignId: null,
      influencerId: null,
      hasMentions: false,
      business: null,
      masonryTimeout: null
    };
  },
  computed: {
    ...mapState('core/auth', ['user']),
    ...mapGetters('subscriptions', ['hasPermission']),
    showDetachDialog: {
      get() {
        return !! this.selectedPostToDetach;
      },
      set() {
        this.selectedPostToDetach = null;
      }
    },
    attachButtonText() {
      if(this.selectedUuids.length > this.maxSaveLimit) {
        return "You can only save " + this.maxSaveLimit + " at a time";
      }

      return "Save selected posts (" + this.selectedUuids.length + ")";
    }
  },
  methods: {
    ...mapActions("core/auth", ["getUserFromApi"]),
    ...mapActions("business", [
      "getInfluencerChannelPostsAttachable",
      "attachInfluencerChannelPosts",
      "detachInfluencerChannelPosts",
      "updateBusiness"
    ]),
    checkInfoDialog() {
      let localStorageName = 'content_learning_dialog_shown';
      let hasShown = localStorage.getItem(localStorageName);

      if(hasShown && hasShown === '1') {
        return;
      }

      localStorage.setItem(localStorageName, '1');
      this.showInfoDialog = true;
    },
    saveContentExplainerImage() {
      return require('@/assets/save-content-explainer.jpg');
    },
    search() {
      this.currentPage = 1;
      this._getInfluencerChannelPosts();
    },
    _getInfluencerChannelPosts() {
      if (this.loading || !this.campaignId) {
        return;
      }

      this.loading = true;
      this.posts = [];

      const params = {
        influencer_id: this.influencerId,
        campaign_id: this.campaignId,
        limit: this.limit,
        page: this.currentPage
      };

      if(this.hasMentions) {
        params.mentions = this.instagramUsernames;
      }

      this.getInfluencerChannelPostsAttachable(params).then(response => {
        this.posts = response.data;

        this.loading = false;

        this.currentPage = response.currentPage;
        this.lastPage = response.lastPage;
        this.total = response.total;
        this.hasMore = (this.currentPage !== this.lastPage);
        this.scrollToTop();
      });
    },
    togglePostSelection(uuid) {
      this.checkInfoDialog();

      let recentlyAttached = this.recentlyAttachedUuids.filter(item => item === uuid);

      if(recentlyAttached.length > 0) {
        this.selectedPostToDetach = recentlyAttached[0];
        return;
      }

      let exists = this.selectedUuids.filter(item => item === uuid).length > 0;

      if(exists) {
        this.selectedUuids = this.selectedUuids.filter(item => item !== uuid);
      } else {
        this.selectedUuids.push(uuid);
      }
    },
    saveSelectedPosts() {
      this.attachInfluencerChannelPosts({
        campaign_id: this.campaignId,
        influencer_channel_post_ids: this.selectedUuids
      }).then(() => {
        this.recentlyAttachedUuids = this.recentlyAttachedUuids.concat(this.selectedUuids);
        this.selectedUuids = [];
        this.showAttachDialog = false;
      });
    },
    detachSelectedPost() {
      this.detachInfluencerChannelPosts({
        campaign_id: this.campaignId,
        influencer_channel_post_ids: [this.selectedPostToDetach]
      }).then(() => {
        this.recentlyAttachedUuids = this.recentlyAttachedUuids.filter(item => item !== this.selectedPostToDetach);
        this.selectedPostToDetach = null;
      });
    },
    saveInstagramUsernames() {
      let params = {
        business_uuid: this.user.business.uuid,
        instagram_usernames: this.instagramUsernames
      };

      this.updateBusiness(params).then(() => {
          this.getUserFromApi();
          this.setSnackSuccess("Saved");
          this.showMentionsDialog = false;
         }, () => {
           this.setSnackError("Something went wrong");
         });
    },
    // This scroll event is a hack to force redraw the masonry grid
    // There's an issue with dynamic sizing, and loading of images
    // This causes the masonry to draw a size that's not loaded
    // Proper fix is to pre calculate sizes for all boxes
    scrollEvent() {
      if(this.posts.length === 0 || this.$vuetify.breakpoint.xsOnly) {
        return;
      }

      if(this.masonryTimeout !== null) {
        clearTimeout(this.masonryTimeout);
      }

      this.masonryTimeout = setTimeout(() => {
        this.$redrawVueMasonry();
      }, 350);
    },
    selectInfluencer(influencer) {
      this.influencerId = influencer.uuid;
      this.page         = 1;
      this.currentPage  = 1;

      this.$refs.influencerAutocomplete.pushInfluencer(influencer);
      this.search();
    }
  },
  updated() {
    // The masonry grid has to be rendered before drawing it
    // This will draw the grid multiple times to provide
    // Better user feedback so it doesn't seem empty
    for(let i = 1; i < 10; i++) {
      let timeout = i * 500;

      setTimeout(() => {
        this.$nextTick(() => {
          this.$redrawVueMasonry();
        });
      }, timeout);
    }
  },
  created() {
    this.instagramUsernames = this.cloneDeep(this.user.business.instagram_usernames);
    this.hasMentions = this.instagramUsernames.length > 0;
  },
  mounted() {
    document.addEventListener('scroll', this.scrollEvent);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollEvent);
  }
};
</script>

<style scoped></style>
